.meteor-shower {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }
  
  .meteor {
    position: absolute;
    top: -50px; /* Start just above the view */
    width: 5px;
    height: 5px;
    background: linear-gradient(45deg, #460ade, rgba(153, 9, 205, 0));
    opacity: 0.8;
    transform: rotate(45deg);
    animation-name: meteor-fall;
    animation-timing-function: ease-in;
  }
  
  @keyframes meteor-fall {
    0% {
      opacity: 0;
      transform: translateY(-50px) translateX(0) rotate(45deg);
    }
    30% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
      transform: translateY(100vh) translateX(1000px) rotate(45deg);
    }
  }
  