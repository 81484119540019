:root {
  --maincolor: #944dff;
  --seccolor: #b185f2;
  --maincolor1: rgb(149, 77, 255);
  --seccolor1: rgb(149, 77, 255);
  --primary: rgba(0, 0, 0, 1);
  --secondary: rgba(0, 0, 0, 0.7);
  --tertiary: rgba(0, 0, 0, 0.5);
}

.App {
  text-align: center;
}

body {
  background-size: cover;
  font-size: 1rem;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--secondary);
  background-attachment: fixed;
  background-color: #f2f2f2;
  height: 100vh;
  
}

a,
.a {
  color: var(--primary);
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

a:hover,
.a:hover {
  color: var(--tertiary);
}

hr {
  opacity: 0.06;
  margin: 20px 30px !important;
}

p,
.p {
  color: var(--secondary);
}

b,
.b {
  color: var(--primary);
}

h1,
.h1 {
  margin-bottom: 0;
}

h2,
.h2 {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 28px;
  font-weight: 800;
  color: var(--primary);
  text-transform: uppercase;
}

@media screen and (max-width: 400px) {

  h2,
  .h2 {
    font-size: 24px;
  }
}

h3,
.h3 {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 24px;
  font-weight: 800;
  color: var(--primary);
  text-transform: uppercase;
}

h4,
.h4 {
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--primary);
}

@media screen and (max-width: 400px) {

  h3,
  .h3 {
    font-size: 20px;
  }

  h4,
  .h4 {
    font-size: 18px;
  }
}

h5,
.h5 {
  color: var(--primary);
  font-weight: 800 !important;
  margin-bottom: 4px;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 2px;
  line-height: 1.3;
}

h6,
.h6 {
  color: var(--primary);
  font-weight: 800 !important;
  font-size: 15px;
  margin-bottom: 4px;
}

@media screen and (max-width: 400px) {

  h6,
  .h6 {
    font-size: 14px;
  }
}

.card {
  position: relative;
  width: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border: 0.5px solid rgba(255, 255, 255, 0.5);
}

.card-fix {
  position: relative;
  width: auto;
  min-width: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border: 0px solid transparent;
}

.card-fix .card-img-top {
  height: 140px !important;
  padding: 0px;
  object-fit: contain;
}

.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.pokemon-card-id small {
  font-size: 10px;
}

.pokemon-card-id {
  display: block;
  min-width: 40px;
  background-color: var(--seccolor);
  border-radius: 9px;
  color: #fff;
  font-size: 15px;
  position: absolute;
  top: 6px;
  left: 6px;
  text-align: center;
  line-height: 25px;
  border: 1px solid var(--maincolor);
  background: var(--maincolor);
  transition: all 0.5s;
  padding: 0px 15px;
}

.glass-card {
  position: relative;
  width: 300px;
  /* Adjust the width as needed */
  padding: 20px;
  margin: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.pokemon-card {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border: 0px solid var(--maincolor);
  border-color: var(--seccolor);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.7);
  padding: 34px 30px;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.pokemon-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.nft-card {
  padding: 34px 30px;
}

.nft-card-left {
  padding: 0 0 0 60px;
}

.nft-card-right {
  padding: 0 60px 0 0;
}

@media screen and (max-width: 800px) {
  .pokemon-card {
    padding: 26px 20px;
  }

  .nft-card {
    padding: 26px 20px;
  }

  .additional-p {
    padding: 32px 28px;
  }

  .nft-card-left {
    padding: 0;
  }

  .nft-card-right {
    padding: 0;
  }
}

.pokemon-card-height {
  height: 560px;
}

.card-height {
  height: 448px;
}

@media screen and (max-width: 1399px) {

  .card-height {
    height: 460px;
  }
}

@media screen and (max-width: 990px) {
  .card-height {
    height: 448px;
  }

  .card-height-unset {
    height: unset;
  }
}

@media screen and (max-width: 767px) {
  .pokemon-card-height {
    height: unset;
  }

  .card-height {
    height: unset;
  }
}

.card-img-top {
  object-fit: cover;
  height: 300px;
  /* Set a fixed height for your images */
  border-radius: 10px;
  mix-blend-mode: multiply;
}

.card-text {
  font-size: 15px;
  line-height: 1.7;
}

.pokemon-card-title {
  text-align: center;
  border-radius: 10px;
  padding: 10px 0;
  font-weight: 800;
  font-size: 25px;
  background-color: transparent;
  color: var(--primary);
  border-bottom: 0px solid transparent;
}

.pokemon-card-body {
  padding: 10px;
}

.card-padding {
  padding: 1rem 1.4rem 0.4rem;
}

@media screen and (max-width: 768px) {

  .card-padding {
    padding: 6px 6px 4px;
  }

}

.pokemon-card-footer {
  padding: 26px 0 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
}

button {
  background: var(--maincolor);
}

.buton {
  background: transparent;
  border: 0px solid transparent;
  border-radius: 8px;
  color: #ffffff;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  padding: 10px 16px;
  transition: all 0.3s ease-in-out;
  background: var(--maincolor);
  min-width: 40px;
  font-size: 15px;
  font-weight: 800;
  box-shadow: 0px 2px 10px rgba(148, 77, 255, 0.3);
}

.buton:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--seccolor);
  border: 0px solid var(--maincolor);
}

.butonDisabled {
  border: 0px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: var(--maincolor) !important;
}

.buton:disabled {
  border: 0px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: #FFFFFF !important;
}

.buton-small {
  border: 0px solid transparent;
  border-radius: 50%;
  color: var(--maincolor);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(148, 77, 255, 0.1);
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-weight: 800;
  margin: 0 auto;
  margin-top: 10px;
  box-shadow: none;
}


.buton-secondary {
  background: rgba(148, 77, 255, 0.15);
  color: rgba(148, 77, 255, 1);
  border-radius: 5px;
  height: 36px;
  box-shadow: none;
  padding: 9px 16px;
  font-size: 12px;
}

.buton-sec-small {
  font-size: 11px;
}

.buton-secondary:hover {
  color: rgba(148, 77, 255, 1);
  background: rgba(148, 77, 255, 0.3);
  box-shadow: none;
}

.btn-success {
  text-transform: uppercase;
  background-color: var(--maincolor) !important;
}

.btn-success:hover {
  background-color: var(--seccolor) !important;
}

.btn-success:disabled {
  background-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.4) !important;
  font-weight: 400;
}

.buton:disabled {
  background-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.4) !important;
  font-weight: 400;
}


.loaderSmall {
  display: block;
  color: var(--seccolor);
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-top: 0px;

}

.loaderBig {
  color: var(--seccolor);
  width: 100px;
  height: 100px;
  margin-top: 100px;

}

.loaderMsg {
  color: var(--seccolor) !important;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 30px;
  font-weight: 700;
}

.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.green-circle {
  background-color: #01a201;
}

.buy-token-container {
  color: white;
  width: 100%;
  max-width: 100%;
  margin: auto;
  box-shadow: 0px 2px 10px rgba(149, 77, 255, 0.3);
  border-radius: 10px;
  padding: 20px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.buy-token-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 400px) {
  .buy-token-header {
    margin-bottom: 8px;
  }

  .header-margin {
    margin-bottom: 14px;
  }
}

.buy-node,
.buy-token {
  background: #252e4f;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.token-exchange-info {
  padding: 14px 10px 18px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: 900;
}

.wallet-balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wallet-button {
  background: #313c5e;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.balance-info {
  padding: 17px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 400px) {
  .token-exchange-info {
    margin-bottom: 10px;
  }

  .wallet-balance {
    margin-bottom: 10px;
  }

  .balance-info {
    margin-bottom: 10px;
  }
}

.swap-form {
  border-radius: 10px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group input,
.input-group select {
  font-size: 15px;
  color: var(--tertiary);
  ;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: rgba(149, 77, 255, 0.01);
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.input-group input:focus-visible {
  color: var(--secondary);
  border: 2px solid rgba(149, 77, 255, 1);
  background: rgba(149, 77, 255, 0.01);
}

.input-group input:hover {
  color: var(--secondary);
  border: 2px solid rgba(149, 77, 255, 1);
  background: rgba(149, 77, 255, 0.01);
}


.balanceDisplay {
  display: inline-block;
  color: var(--primary);
  font-weight: 700;
  font-size: 16px;
  padding: 0px;
  border-radius: 10px;
  margin-bottom: 0px;
  margin-right: 20px;
  width: 100%;
}

@media screen and (max-width: 400px) {
  .balanceDisplay {
    margin-right: 0px;
    padding: 24px 14px;
  }
}

.balanceDisplay img {
  width: 30px;
  margin-right: 8px;
}

.max-button {
  background: transparent;
  color: var(--maincolor);
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}


.gas-info {
  font-size: 14px;
  padding: 12px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color: var(--tertiary);
  opacity: 0.5;
}

.swap-button {
  background: var(--seccolor);
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.top-bar {
  background: #f0f0f0;
  box-shadow: rgb(0, 0, 0, 0.15) 10px 0px 20px;
  padding: 10px 0;
  z-index: 100;
  margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
  .top-bar {
    padding: 10px 0 20px;
  }
}

.menu {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

@media screen and (max-width: 800px) {
  .menu {
    padding-left: 0px;
  }
}

.menuBtn {
  padding: 20px 30px 16px;
  margin: 0 10px !important;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  background-color: transparent;
  border: 0px;
  margin-right: 0px;
  color: var(--tertiary);
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  box-shadow: none;
  width: auto;
}

.menuBtn svg {
  margin-top: -3px;
  margin-right: 7px;
  font-size: 18px;
}

.menuBtn:hover {
  color: var(--primary);
  background-color: transparent;
  border: 0px;
}

@media screen and (max-width: 992px) {
  .menuBtn {
    padding: 16px 10px 10px;
    font-size: 12px;
  }
}

@media screen and (max-width: 390px) {
  .menuBtn {
    padding: 16px 10px 10px;
    font-size: 11.5px;
  }
}

.dropdown-padding {
  padding: 12px 0 8px
}

@media screen and (max-width: 992px) {
  .dropdown-padding {
    padding: 8px 10px 2px
  }
}

.connectButton {
  background-color: #01a201 !important;
}

.tokenIconColor {
  color: var(--maincolor);
  ;
}

.loaderScreen {
  display: block;
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.alert {
  padding: 20px;
}


.smallBtn {
  display: block;
  width: auto;
  padding: 6px 25px;
  margin-left: 6px;
  border-radius: 6px;
  float: right;
}


.plusButton {
  display: block;
  border: 0px;
  margin: 0 auto;
  background: var(--maincolor);
  border-radius: 10px;
  padding: 30px 10px;
  color: #FFFFFF !important;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
}

.plusButton:hover {
  box-shadow: none;
  background: var(--maincolor);
  transition: all 0.3s ease-in-out;
  box-shadow: rgb(195, 195, 195) 0px 0px 20px;
}

.plusButton p {
  color: #FFFFFF !important;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
  margin-bottom: 12px;
}

.plusButton img {
  width: 20%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 160px;
  /* 1:1 Aspect Ratio */
}

.iframe-container-small {
  position: relative;
  width: 70px;
  padding-top: 80px;
  display: inline-block;
  margin-left: -25px;
  margin-top: -70px;
  margin-right: 20px;
}

@media screen and (max-width: 400px) {
  .iframe-container-small {
    margin-left: -20px;
  }
}

.iframe-container iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  max-height: 280px;

}

.iframe-container-small iframe {
  position: absolute;
  top: 35px;
  left: 10px;
  width: 100%;
  height: 100%;
  border: none;
  /* Optionally, to remove iframe border */
  border-radius: 10px;
  z-index: 100;
}

w3m-button {
  margin: 0 auto;
  color: var(--maincolor) !important;
}

wui-text {
  color: red;
}

.progress,
.progress-stacked {
  --bs-progress-bar-bg: var(--maincolor);
  --bs-progress-bg: rgba(0, 0, 0, 0.03);
}

.progress-bar {
  color: transparent !important;
}

.node-item {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border: 0px solid var(--maincolor);
  border-color: var(--seccolor);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s ease-in-out;
  position: relative;
}

.node-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 400px) {
  .node-item {
    padding: 24px 14px;
  }
}

.node-image img {
  width: 50px;
  /* Adjust the size based on your design */
  height: 50px;
  margin-right: 15px;
  /* Provides spacing between the image and the text */
}

.node-info {
  display: flex;
  flex-grow: 1;
  align-items: center;
  /* Align text content vertically in the center */
}

.node-id,
.node-name,
.node-rewards {
  margin-right: 15px;
  /* Provides spacing between text elements */
}

.node-id {
  font-size: 0.9rem;
  color: #333333;
  font-weight: bold;
}

.node-name {
  font-size: 1.1rem;
  color: #333333;

}

.node-rewards {
  font-size: 0.9rem;
  color: #999999;
  flex-grow: 1;
  /* Allows this element to take up any available space */
}

button {
  padding: 5px 10px;
  background-color: var(--maincolor);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.btn-copy {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 100px;
  padding: 6px 20px;
  color: var(--tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  height: 30px;
}

.btn-copy:hover {
  background: rgba(0, 0, 0, 0.08);
  color: var(--tertiary);
}

.custom-select {
  border-radius: 10px !important;
}

.custom-select:focus {
  outline: none;
  /* Remove focus outline */
  border-color: rgb(148, 77, 255);

}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

button.pulse-button {
  animation: borderPulse 1200ms infinite ease-out;
}

@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(227, 219, 254, .4), 0px 0px 0px 0px rgba(148, 77, 255, 0.4);
  }

  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(148, 77, 255, .2), 0px 0px 0px 10px rgba(148, 77, 255, 0);
  }
}


.table {
  --bs-table-bg: transparent;
}

.alert-warning {
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
}

.rounded-pill {
  background-color: rgba(148, 77, 255, 0.7) !important;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: 800;
  display: block;
}

.pill-blue {
  background-color: rgba(77, 95, 255, 0.7) !important;
}

.pill-pink {
  background-color: rgba(237, 77, 255, 0.7) !important;
}

.bg-success {
  background-color: rgba(53, 182, 62, 0.8) !important;
  color: #FFFFFF;
}

.bg-danger {
  background-color: rgba(255, 191, 0, 0.8) !important;
}

.progress {
  background-color: rgba(133, 133, 133, 0.13);
}

.backgroundDecoration {
  position: absolute;
  z-index: -1;
  width: 70%;
  left: 15%;
  top: 25%;
  opacity: 0.3;
}

.height50 {
  height: 50px !important;
  max-height: 50px !important;
}

.height70 {
  height: 70px !important;
  max-height: 70px !important;
}

.height50 {
  height: 50px !important;
  max-height: 50px !important;
}

.modal-content {
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0 25px;
  background-color: rgba(255, 255, 255, 0.9);
}

.frame-card {
  height: 300px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 50px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  display: block;
  margin-bottom: 30px;
}

.navbar-toggler-icon {
  background-color: transparent;
}

.navbar-toggler {
  border: 0px;
}

.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.custom-card .card-title {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.custom-card .card-text {
  font-size: 15px;
  color: var(--secondary);
}

.list-group-item {
  border: none;
  padding: 10px 0;
}


.assistant-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.assistant-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.assistant-icon {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.assistant-button:hover {
  background-color: transparent;
}

.chat-message {
  white-space: pre-wrap;
  margin-bottom: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 10px;
}

.tokenCardId {
  background: var(--maincolor);
  opacity: 0.4;
  font-weight: 800;
  color: #FFFFFF;
  border-radius: 10px 0px 10px 0px;
  padding: 5px 10px;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 10px;
}

.navbar-nav .dropdown-menu {
  background: #f0f0f0;
  padding: 10px 0;
  border: 0px solid transparent;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  color: var(--tertiary);
}

@media screen and (max-width: 1200px) {

  .navbar-nav .dropdown-menu {
    box-shadow: none;
  }
}

.dropdown-menu .nav-link {
  color: var(--tertiary);
  font-size: 13px;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .dropdown-menu .nav-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 390px) {
  .dropdown-menu .nav-link {
    font-size: 11.5px;
  }
}

.dropdown-menu .nav-link:hover {
  color: var(--primary);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.bg-secondary {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.scrollable-row {
  overflow-x: unset;
  white-space: nowrap;
  scrollbar-color: var(--maincolor);
  scrollbar-width: thin;
  padding-bottom: 15px;
}

@media screen and (max-width: 1024px) {

  .scrollable-row {
    overflow-x: scroll;
  }
}

.scrollable-row .row {
  display: flex;
  flex-wrap: nowrap;
}

.scrollable-row .col {
  flex: 0 0 auto;
}


.levelImg {
  width: 80px;
}

.levelTitle {
  cursor: pointer;
  background: var(--seccolor);
  width: auto;
  color: #FFFFFF;
  position: absolute;
  z-index: -1;
  top: 12px;
  left: 42px;
  padding: 5px 15px 10px 35px;
  border-radius: 40px;
  font-size: 20px;
}

.levelTitle small {
  font-size: 12px;
}

.level-pill {
  background: var(--seccolor);
  color: #FFFFFF;
  padding: 5px 18px 5px 18px;
  border-radius: 30px;
  font-size: 20px;
  text-transform: capitalize;
}

.level-pill-big {
  background: var(--seccolor);
  color: #FFFFFF;
  padding: 15px 18px;
  border-radius: 30px;
  font-size: 25px;
  text-transform: capitalize;
  margin-top: 0px;
}

.level-pill small {
  font-size: 12px;
}

.level-card {
  position: relative;
  cursor: pointer;
}

.navbar-brand {
  margin-right: 0px;
}

.navbar-toggler {
  color: transparent !important;
}

.nav-link {
  color: var(--tertiary);
}

.modal-header {
  border-bottom: 0px solid transparent;
  padding: 1rem 2rem 0;
}

.modal-footer {
  border-top: 0px solid transparent;
  padding: 1.5rem 2rem;
}

.modal-body {
  border-bottom: 0px solid transparent;
  padding: 1rem 2rem 0;
}

@media screen and (max-width: 600px) {
  .modal-header {
    padding: 0.5rem 1rem 0;
  }

  .modal-footer {
    padding: 1rem 1rem;
  }

  .modal-body {
    padding: 0.5rem 1rem 0;
  }
}

.alert-secondary {
  background: transparent;
  border: 0px;
  box-shadow: none;


}

.iframe-containerr {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* Aspect ratio 16:9 */
}

.iframe-containerr iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.loaderCard {
  padding-top: 150px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
}

.card-balance {
  height: 252px;
}

.card-title {
  font-size: 18px;
}

.card-balance-r {
  height: 252px;
}

@media screen and (max-width: 600px) {
  .card-title {
    font-size: 16px;
  }

  .card-balance-r {
    height: unset;
  }
}

.modal-header .btn-close {
  position: absolute;
  top: 28px;
  right: 24px;
}

.btn-close:focus {
  box-shadow: none;
}

.footer {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  font-size: 14px;
  background: #f0f0f0;
  box-shadow: rgb(0, 0, 0, 0.1) 10px 0px 16px;
  z-index: 3;
}

.social-icon {
  margin: 0 10px;
  color: rgba(0, 0, 0);
  transition: all 0.3s;
  text-decoration: none;
  opacity: 0.6;
}

@media screen and (max-width: 400px) {
  .social-icon {
    margin: 0 8px;
    font-size: 13px;
  }
}

.twitter:hover {
  opacity: 1;
}

.telegram:hover {
  color: #0088cc;
  opacity: 1;
}

.instagram:hover {
  color: #E1306C;
  opacity: 1;
}

.platform:hover {
  color: #944dff;
  opacity: 0.9;
}

.station:hover {
  color: rgb(237, 77, 255);
  opacity: 0.8;
}

.accordion {
  border-radius: 8px;
  --bs-accordion-bg: rgba(255, 255, 255, 0.5);
  background-color: #f4f2f7;
  --webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border: 0px solid transparent !important;
  --bs-accordion-border-color: transparent !important;
}

.accordion-header {
  border-radius: 8px;
  --bs-accordion-bg: transparent;
  border: 0px solid transparent;
}

.accordion-button {
  color: var(--primary);
  font-weight: 700 !important;
  font-size: 16px;
  padding: 26px 28px;
  background-color: transparent !important;
}

.accordion-body {
  border-radius: 8px !important;
  padding: 0px 28px 10px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: var(--primary) !important;
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
  border-radius: 8px !important;
}

.accordion-item {
  border-radius: 8px !important;
  font-size: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 400px) {
  .accordion-button {
    font-size: 15px;
    padding: 22px;
  }

  .accordion-body {
    padding: 0px 22px 6px;
  }

  .accordion-item {
    font-size: 14px;
  }
}

.craft-item {
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
}


/* src/components/FlipNotification.css */
.notification {
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: rgb(235, 235, 39);
  padding: 10px;
  margin: 0px;
  text-align: center;
  border-radius: 0px;
  font-weight: 800;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.flipped {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipped .front,
.flipped .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flipped .front {
  transform: rotateY(0deg);
}

.flipped .back {
  transform: rotateY(180deg);
}


@keyframes float {
  0% {

    transform: translatey(0px) rotateZ(0deg);

  }

  25% {

    transform: translatey(0px) rotateZ(5deg);
  }

  50% {

    transform: translatey(0px) rotateZ(-10deg);
  }

  75% {

    transform: translatey(0px) rotateZ(5deg);
  }

  100% {

    transform: translatey(0px) rotateZ(0deg);
  }
}

.floatAvatar {
  animation: float 6s ease-in-out infinite;
}

.refAccordion .accordion-button {
  height: 46px;
  color: var(--maincolor);
  box-shadow: none;
  padding: 10px 16px;
  font-size: 15px;
  text-align: center;
  font-weight: 800 !important;
}

.refAccordion .accordion-item {
  background: rgba(148, 77, 255, 0.15)
}

.pageTitle {
  font-size: 28px;
  font-weight: 800;
  color: var(--primary);
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: center;
}

.platformSection {
  position: relative;
  min-height: 220px;
  padding: 40px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: white;
  background-image: url("./platBack.png");
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1199px) {
  .platformSection {
    min-height: 278px;
  }
}

@media screen and (max-width: 767px) {
  .platformSection {
    min-height: unset;
  }
}

.platformSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
}

.platformSection:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.platformSection p {
  position: relative;
  color: white;
  opacity: 0.9;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0 20px;
  z-index: 2;
}

.platformSection h5 {
  position: relative;
  color: white;
  font-size: 22px;
  z-index: 2;
}

.platformSection a {
  position: relative;
  z-index: 2;
}

.stationSection {
  background-image: url("./stationBack.png");
}

.nodeSection {
  position: relative;
  min-height: 253px;
  padding: 40px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: white;
  background-image: url("./nodeBack.png");
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}

.nodeSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
}

.nodeSection:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.nodeSection p {
  position: relative;
  color: white;
  opacity: 0.9;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0 20px;
  z-index: 2;
}

.nodeSection h5 {
  position: relative;
  color: white;
  font-size: 22px;
  z-index: 2;
}

.nodeSection a {
  position: relative;
  z-index: 2;
}

.whyNodeSection {
  background-image: url("./whyNodeBack.png");
}


@media screen and (max-width: 400px) {
  .whyNodeSection {
    min-height: unset;
  }
}

.introSection {
  position: relative;
  min-height: 300px;
  padding: 40px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-image: url("./introBack.png");
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  /* Ensures the section does not exceed the screen width */
  overflow-x: auto;
  /* Enables horizontal scrolling if the section exceeds the screen width */
}

.introSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1;
}

@media (max-width: 900px) {
  .introSection {
    padding: 20px 10px;
    /* Adjust padding for smaller screens */
    min-height: 300px;
    /* Adjust height to be more appropriate for small screens */
    background-position: unset;
    background-image: url("./introBackMobile.png");
  }
}

@media (max-width: 760px) {
  .introSection {
    padding: 20px 10px;
    /* Adjust padding for smaller screens */
    min-height: 250px;
    /* Adjust height to be more appropriate for small screens */
    background-position: unset;
    background-image: url("./introBackMobile.png");
  }
}

@media (max-width: 700px) {
  .introSection {
    padding: 20px 10px;
    /* Adjust padding for smaller screens */
    min-height: 230px;
    /* Adjust height to be more appropriate for small screens */
    background-position: unset;
    background-image: url("./introBackMobile.png");
  }
}

@media (max-width: 450px) {
  .introSection {
    padding: 20px 10px;
    /* Adjust padding for smaller screens */
    min-height: 190px;
    /* Adjust height to be more appropriate for small screens */
    background-position: unset;
    background-image: url("./introBackMobile.png");
  }
}

@media (max-width: 400px) {
  .introSection {
    padding: 20px 10px;
    /* Adjust padding for smaller screens */
    min-height: 180px;
    /* Adjust height to be more appropriate for small screens */
    background-position: unset;
    background-image: url("./introBackMobile.png");
  }
}

@media (max-width: 380px) {
  .introSection {
    padding: 20px 10px;
    /* Adjust padding for smaller screens */
    min-height: 160px;
    /* Adjust height to be more appropriate for small screens */
    background-position: unset;
    background-image: url("./introBackMobile.png");
  }
}

.video-introduction {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #944dff !important;
}

.css-z15j16-control:hover {
  border-color: #944dff !important;
  box-shadow: none !important;
}

.css-1ymg8of-control {
  border-color: #944dff !important;
  box-shadow: none !important;
}

.qwrlds-card {
  min-width: 210px
}

@media screen and (max-width: 450px) {
  .qwrlds-card {
    min-width: unset
  }
}

.loading-card {
  min-width: 210px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 24px;
}

@media screen and (max-width: 450px) {
  .loading-card {
    min-width: unset;
    padding: 14px
  }
}


.admin .form-check-label {
  display: none;
}

.admin .form-check-inline {
  margin-right: 0px;
  margin-top: 0px;
}

.admin .form-check .form-check-input {
  height: 20px;
  width: 20px;
  float: none;
  margin-left: -45px;
}

.admin .selectSmall {
  height: 40px;
  max-width: 150px;
  padding: 0px;
  border-radius: 8px !important;
  margin-right: 10px;
}

.admin .clear {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.refBtnHelp{
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 15px;
  position: absolute;
  top: 8px;
  right: 5px;
  background-color: var(--seccolor);
  border-color: var(--seccolor);
  padding: 0px;
  padding-left: 5px;
}

.refBtnHelp:hover{
  background-color: var(--maincolor);
  border-color: var(--seccolor);
}

/* HTML: <div class="loadertext"></div> */
.loadertext {
  --w:10ch;
  font-weight: bold;
  font-family: monospace;
 
  letter-spacing: calc(10*var(--w));
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  color: #0000;
  animation: l18 3s infinite;
  text-align: center;
  margin: 0 auto;
}
.loadertext:before {
  content:"Claiming...";
}

@keyframes l18 {
   0% {text-shadow: 
        calc(  9*var(--w)) 0 var(--maincolor),calc( -1*var(--w)) 0 var(--maincolor),calc(-11*var(--w)) 0 var(--maincolor),calc(-21*var(--w)) 0 var(--maincolor),calc(-31*var(--w)) 0 var(--maincolor), 
        calc(-41*var(--w)) 0 var(--maincolor),calc(-51*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
   4% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc( -1*var(--w)) 0 var(--maincolor),calc(-11*var(--w)) 0 var(--maincolor),calc(-21*var(--w)) 0 var(--maincolor),calc(-31*var(--w)) 0 var(--maincolor), 
        calc(-41*var(--w)) 0 var(--maincolor),calc(-51*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
   8% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-11*var(--w)) 0 var(--maincolor),calc(-21*var(--w)) 0 var(--maincolor),calc(-31*var(--w)) 0 var(--maincolor), 
        calc(-41*var(--w)) 0 var(--maincolor),calc(-51*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  12% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-21*var(--w)) 0 var(--maincolor),calc(-31*var(--w)) 0 var(--maincolor), 
        calc(-41*var(--w)) 0 var(--maincolor),calc(-51*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  16% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-31*var(--w)) 0 var(--maincolor), 
        calc(-41*var(--w)) 0 var(--maincolor),calc(-51*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  20% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-41*var(--w)) 0 var(--maincolor),calc(-51*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  24% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-51*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  28% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-61*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  32% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-71*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  36% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-81*var(--w)) 0 var(--maincolor)}
  40%,
  60% {text-shadow: 
        calc(  0*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  64% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-10*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  68% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-20*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  72% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-30*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  76% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-39*var(--w)) 0 var(--maincolor),calc(-40*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  80% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-39*var(--w)) 0 var(--maincolor),calc(-49*var(--w)) 0 var(--maincolor), 
        calc(-50*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  84% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-39*var(--w)) 0 var(--maincolor),calc(-49*var(--w)) 0 var(--maincolor), 
        calc(-59*var(--w)) 0 var(--maincolor),calc(-60*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  88% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-39*var(--w)) 0 var(--maincolor),calc(-49*var(--w)) 0 var(--maincolor), 
        calc(-59*var(--w)) 0 var(--maincolor),calc(-69*var(--w)) 0 var(--maincolor),calc(-70*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  92% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-39*var(--w)) 0 var(--maincolor),calc(-49*var(--w)) 0 var(--maincolor), 
        calc(-59*var(--w)) 0 var(--maincolor),calc(-69*var(--w)) 0 var(--maincolor),calc(-79*var(--w)) 0 var(--maincolor),calc(-80*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  96% {text-shadow: 
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-39*var(--w)) 0 var(--maincolor),calc(-49*var(--w)) 0 var(--maincolor), 
        calc(-59*var(--w)) 0 var(--maincolor),calc(-69*var(--w)) 0 var(--maincolor),calc(-79*var(--w)) 0 var(--maincolor),calc(-89*var(--w)) 0 var(--maincolor),calc(-90*var(--w)) 0 var(--maincolor)}
  100% {text-shadow:
        calc(-9*var(--w)) 0 var(--maincolor),calc(-19*var(--w)) 0 var(--maincolor),calc(-29*var(--w)) 0 var(--maincolor),calc(-39*var(--w)) 0 var(--maincolor),calc(-49*var(--w)) 0 var(--maincolor), 
        calc(-59*var(--w)) 0 var(--maincolor),calc(-69*var(--w)) 0 var(--maincolor),calc(-79*var(--w)) 0 var(--maincolor),calc(-89*var(--w)) 0 var(--maincolor),calc(-99*var(--w)) 0 var(--maincolor)}
}

/* HTML: <div class="loader"></div> */
.loaderclaim {
  width: 80px;
  aspect-ratio: 1;
  position:relative;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
.loaderclaim:before,
.loaderclaim:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background:
    radial-gradient(circle 10px,var(--maincolor) 94%,#0000),
    repeating-conic-gradient(from -30deg,#0000 0 60deg,var(--maincolor) 61deg 120deg);
  -webkit-mask:radial-gradient(circle 15px,#000 calc(100% - 6px),#0000 calc(100% - 5px) 94%,#000);
}
.loaderclaim:after {
  animation: l4 1s infinite;
  transform: perspective(300px) translateZ(0px)
}
@keyframes l4 {
  to {transform:perspective(300px) translateZ(150px);opacity:0}
}


.two-column-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.column {
  flex: 1;
}

.column p{
  font-size: 15px;
}
